import cx from "classnames";
import React from "react";
import css from "./Test.module.scss";

const TestController = () => {
  return (
    <>
      <section className="container-fluid">
        <div className={cx(css["menu-description"], css["container-sm"])}>
          <h2>Test Page Changed</h2>
        </div>
      </section>
    </>
  );
};

export default TestController;

import React from "react";
import { Redirect, Route } from "react-router-dom";
import MenuController from "./routes/MenuController";
import TestController from "./routes/TestController";

const Routes = () => {
  return (
    <>
      <Route exact path="/" render={() => <Redirect to="/menu" />} />
      <Route exact path="/menu" component={MenuController} />
      <Route exact path="/new-menu-test" component={TestController} />
    </>
  );
};

export default Routes;

import cx from "classnames";
import MenuItem from "./components/Menuitem";
import css from "./Menu.module.scss";

const MenuController = () => {
  return (
    <>
      <section className="container-fluid">
        <div className={cx(css["menu-description"], css["container-sm"])}>
          <h2>MORNING NIGHTS</h2>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <section className={css["menu-section"]}>
                <div className={css["menu-section__header"]}>
                  <h2>DIM SUM/ENTREES</h2>
                </div>
                <ul>
                  <MenuItem
                    name="SHUMAI"
                    details={[{
                      description: "Traditional Chinese Dumpling with Plant Based Protein, Mushroom, and Carrots.",
                      price: "9.5"
                    }]}
                  />
                  <MenuItem
                    name="SPICY WONTONS"
                    details={[{
                      description: "Oyster Mushroom, Brussel Sprouts, and Cabbage Wontons served with Spicy Sichuan Chili Oil. Topped with Cilantro, Sesame Seeds, and Scallions.",
                      price: "12"
                    }]}
                  />
                  <MenuItem
                    name="XIAO LONG BAO"
                    details={[{ 
                      description: "Juicy Dumpling with Plant Based Protein, Ginger, and Scallions. Served with Black Vinegar and Ginger.",
                      price: "9.5", 
                    },]}
                  />

                  <MenuItem
                    name="DAIKON CAKE"
                    details={[{
                      description: "Savory Daikon Cake with OmniPork, Plant Based Shrimp, Shiitake Mushrooms, and Scallions. Served with Hoison Sauce.",
                      price: "8.5",
                    },]}
                  />

                  <MenuItem
                    name="SCALLION PANCAKE ROLL"
                    details={[{
                      description: "Scallion Pancake Stuffed with Plant Based Protein Slices, Cucumbers, Scallions, and Cilantro in Hoisin Sauce.",
                      price: "15",
                    },]}
                  />

                  <MenuItem
                    name="SEASONAL VEGETABLE FRIED RICE (GF)"
                    details={[{
                      description: "Wok Fried Rice with Assorted Seasonal Vegetables. Topped with House Ginger Scallion Oil, Fried Shallots and Fried Garlic.",
                      price: "11",
                      addons: [{
                        name: "Add OmniPork Spam",
                        price: "+3",
                      },
                      {
                        name: "Add Plant Based Egg",
                        price: "+3",
                      }]
                    }]}
                  />

                  <MenuItem
                    name="STICKY RICE LOTUS LEAF WRAPS"
                    details={[{
                      description: "Sticky Rice wrapped in Lotus Leaf with Shiitake Mushrooms, Scallions, and OmniPork Strips. Two pieces per order.",
                      price: "10",
                    },]}
                  />

                  <MenuItem
                    name="WALNUT SHRIMP"
                    details={[{
                      description: "Battered Plant Based Shrimp with Candied Walnuts tossed in House Vegan Mayo Sauce.",
                      price: "12.5",
                    },]}
                  />

                  <MenuItem
                    name="SALT & PEPPER OYSTER MUSHROOMS"
                    details={[{
                      description: "Crispy Oyster Mushrooms Dry Fried with Chilies, Thai Basil, Garlic, and Five Spice.",
                      price: "13.5",
                    },]}
                  />

                  <MenuItem
                    name="ORANGE CHICKEN"
                    details={[{ 
                      description: "Battered and Fried Plant Based Chicken tossed in House Orange Sauce. Topped with Scallions and Sesame Seeds. Served with Rice.",
                      price: "14",
                    },]}
                  />

                  <MenuItem
                    name="DAN DAN NOODLES"
                    details={[{
                      description: "Plant Based Protein Grounds and Noodles tossed in Sichuan Sesame Sauce. Topped with House Pickled Mustard Greens, Cilantro, Cucumbers, Scallions, and Sesame Seeds.",
                      price: "13",
                    },]}
                  />

                  <MenuItem
                    name="MAPO TOFU"
                    details={[{
                      description: "Plant Based Protein Grounds and Tofu in a Sichuan Peppercorn sauce. Topped with Scallions and Sesame Seeds. Served with Rice.",
                      price: "12.5",
                    },]}
                  />

                  <MenuItem
                    name="TARO EGG ROLLS"
                    details={[{
                      description: "Crispy Egg Rolls filled with Taro, Wood Ear Mushrooms, Glass Noodles, Carrots, and Cabbage. Served with House Sweet and Sour Sauce. Three pieces per order.",
                      price: "6.5",
                    },]}
                  />
                  <li>
                    <img
                      className={cx(
                        css["menu-qr"],
                        "img-responsive",
                        "center-block"
                      )}
                      alt="Menu QR code"
                      src={process.env.PUBLIC_URL + "/qr_menu.png"}
                    ></img>
                  </li>
                </ul>
              </section>
            </div>
            <div className="col-md-6">
              <section className={css["menu-section"]}>
                <div className={css["menu-section__header"]}>
                  <h2>COCKTAILS (PATIO BAR ONLY)</h2>
                </div>
                <ul>
                  <MenuItem
                    name="LYCHEE RUM PUNCH"
                    details={[{
                      description: "Malibu Rum, Lychee Liqueur, Orgeat, Pineapple and Lemon Juice.",
                      price: "13"
                    }]}
                  />

                  <MenuItem
                    name="CUCUMBERITA"
                    details={[{
                      description: "Cucumber Infused Tequila, Triple Sec, Lime Juice, and Hibiscus Syrup served with a Tajin Rim.",
                      price: "13"
                    }]}
                  />

                  <MenuItem
                    name="5 SPICE WHISKEY SOUR"
                    details={[{
                      description: "Suntory Toki, Lemon Juice, Aquafaba, 5 Spice Infused Simple Syrup, garnished with Spiced Orange Bitters and a Maraschino Cherry.",
                      price: "13"
                    }]}
                  />

                  <MenuItem
                    name="PALOMA/SPICY PALOMA"
                    details={[{
                      description: "Sauza Tequila, Grapefruit Liqueur, Bitters, Lime Juice, and Soda served with a Salted Rim. Also available Spicy.",
                      price: "12"
                    }]}
                  />

                  <MenuItem
                    name="GARDEN OF ZEN"
                    details={[{
                      description: "Butterfly Pea Infused Gin, Elderflower Liqueur, Lime Juice, and Soda.",
                      price: "13"
                    }]}
                  />
                </ul>
              </section>
            </div>
            <div className="col-md-6">
              <section className={css["menu-section"]}>
                <div className={css["menu-section__header"]}>
                  <h2>ADDITIONAL DRINKS</h2>
                </div>
                <ul>
                  <MenuItem
                    name="NON-ALCOHOLIC BEVERAGES"
                    details={[{
                      description: "Fiji Bottled Water||Pepsi||Pepsi Zero Sugar||Sierra Mist||Brisk Iced Tea (Unsweetened)||Lemonade",
                      price: "3"
                    }]}
                  />
                  <MenuItem
                    name="SAKE"
                    details={[{
                      description: "Hana Sake (Lychee or Fiji Apple)||(Bottle - 750 ml)",
                      price: "25"
                    }]}
                  />

                  <MenuItem
                    name="BEER"
                    details={[
                      {
                        description: "Lucky Buddha Lager||(Bottle - 4.8% ABV, 12 IBU)",
                        price: "7"
                      },
                      {
                        descriptionPrePrice: "+2",
                        description: " Make it a Michelada",
                        price: ""
                      },

                    ]}
                  />

                </ul>
              </section>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default MenuController;

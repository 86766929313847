import cx from "classnames";
import css from './MenuItem.module.scss';

interface Addon {
  name: string;
  price: string;
}
interface MenuItemProps {
  name: string;
  details: {
    descriptionPrePrice?: string;
    description: string;
    price?: string;
    addons?: Addon[]
  }[];
}

const MenuItem = ({ name, details }: MenuItemProps) => {

  return (
    <li className={css["menu-item"]}>
      {name && (
        <p className={css["menu-item__heading"]}>{name}</p>
      )}
      {details.map((detail, index) => {
        const descriptionWithBreaks = detail.description.split('||').map((line, index) => (
          <p key={index}>
            {line}
            {index < detail.description.length - 1 && <br />}
          </p>
        ));
        return (<>
          {detail.descriptionPrePrice && (
            <>
              <strong>
                <span className={css["menu-item__currency"]}>$</span>
                {detail.descriptionPrePrice}
              </strong>
              <span>{detail.description}</span>
            </>
          )}

          {!detail.descriptionPrePrice && descriptionWithBreaks}
          <p
            className={cx(
              css["menu-item__details"],
              css["menu-item__details--price"]
            )}
          >
            <strong>
              <span className={css["menu-item__currency"]}>{detail.price ? '$' : ''}</span>
              {detail.price}
            </strong>
          </p>
          {
            detail.addons && (
              <p>
                {detail.addons.map((addon) => (
                  <>
                    <strong>
                      <span className={css["menu-item__currency"]}>$</span>
                      {addon.price}
                    </strong>
                    <span> {addon.name}</span>
                    <br />
                  </>
                ))}
              </p>
            )
          }
          {details.length >= 2 && details.length > index + 1 && (
            <br />
          )}

        </>
        )
      })}
    </li>
  );
}

export default MenuItem;

